import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios"
import NotFound from './views/NotFound.vue'
import store from './store'


const app = createApp(App)
app.use(store)
app.use(router)
app.config.globalProperties.axios = axios
app.component('NotFound', NotFound) //Глобальная регистрация компонента
app.mount('#app')

