import axios from "axios"

export default {
    namespaced: true,
    state() {
        return {
            ordersData: [],
            loading: true
        }
    },
    getters: {
        getOrders(state) {
            return state.ordersData
        },
        getLoading(state) {
            return state.loading
        }
    },
    mutations: {
        SET_ORDERS(state, payload) {
            state.ordersData = payload
        },
        SET_LOADING(state, payload) {
            state.loading = payload
        }
    },
    actions: {
        async loadOrders({ commit }, payload) {
            try {
                let response = await axios.get(
                    "https://panacea-coffee.ru/wp-json/wc/store/products/?per_page=50"
                );
                commit('SET_ORDERS', response.data)
                commit('SET_LOADING', false)
            } catch (error) {
                console.log("Ошибка: " + error);
            }
        }
    }
}