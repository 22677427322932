<template>
  <header class="header">
    <div class="container">
      <div class="header__content">
        <img
          class="logo"
          src="https://panacea-coffee.ru/wp-content/themes/coffee-by-dumitru/assets/img/logo.svg"
          alt=""
        />
        <Navbar />
      </div>
    </div>
  </header>
</template>

<script>
import Navbar from "./Navbar.vue";
export default {
  components: {
    Navbar,
  },
};
</script>

<style scoped>
.header {
  background: #ffb800;
  padding: 30px 0;
}
.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 140px;
}
</style>