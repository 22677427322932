<template>
  <nav class="navbar">
    <ul>
      <li>
        <router-link to="/">Главная</router-link>
      </li>
      <li>
        <router-link to="/orders">Заказы</router-link>
      </li>
      <li>
        <router-link to="/about">О проекте</router-link>
      </li>
      <li>
        <router-link to="/auth/login">Авторизация</router-link>
      </li>
      <li>
        <router-link to="/auth/register">Регистрация</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style scoped>
.navbar {
}
.navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.navbar ul li {
  margin-left: 20px;
}
.navbar ul li a {
  color: white;
}
.navbar ul li a.router-link-exact-active {
  font-weight: bold;
}
</style>