import { createStore } from 'vuex'
import orders from './orders'



// Create a new store instance.
export default createStore({
    modules: {
        orders,
    }
})
