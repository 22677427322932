<template>
  <Header />
  <div class="content">
    <router-view />
  </div>
</template>
<script>
import Header from "./components/Header.vue";
export default {
  components: {
    Header,
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;700&display=swap");

body {
  margin: 0;
  font-family: "Exo 2", sans-serif;
  color: #000000;
}

.container {
  position: relative;
  padding: 0 25px;
}

a {
  text-decoration: none;
  color: inherit;
}

@media (min-width: 575.98px) {
  .container {
    max-width: 520px;
    margin: auto;
    padding: 0;
  }
}

@media (min-width: 767.98px) {
  .container {
    max-width: 740px;
  }
}

@media (min-width: 991.98px) {
  .container {
    max-width: 950px;
  }
}

@media (min-width: 1199.98px) {
  .container {
    max-width: 1099px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1400px;
  }
}
</style>
